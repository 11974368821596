declare var CONFIG: {
  rum_token: string;
  rum_environment: string;
  pendo_api_key: string;
  google_api_key: string;
  launchdarkly_client_id: string;
};

export const RUM_TOKEN = CONFIG.rum_token;
export const RUM_ENVIRONMENT = CONFIG.rum_environment;
export const PENDO_API_KEY = CONFIG.pendo_api_key;
export const GOOGLE_API_KEY = CONFIG.google_api_key;
export const LAUNCHDARKLY_CLIENT_ID = CONFIG.launchdarkly_client_id;
