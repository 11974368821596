import { GOOGLE_API_KEY } from "./utils/config";

const script = document.createElement("script");

script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places&loading=async`;
script.async = true;
script.defer = true;
script.type = "text/javascript";

document.head.appendChild(script);
