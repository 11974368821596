import SplunkOtelWeb from "@splunk/otel-web";
import { RUM_TOKEN, RUM_ENVIRONMENT } from "./utils/config";

SplunkOtelWeb.init({
  realm: "au0",
  rumAccessToken: RUM_TOKEN,
  applicationName: "wrkr-one",
  deploymentEnvironment: RUM_ENVIRONMENT,
  version: "0.1",
});
